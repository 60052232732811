import {Link, useNavigate} from 'react-router-dom';
import {ReactNode} from 'react';
import {AuthLayoutFooter} from './auth-layout-footer';
import {useIsDarkMode} from '@ui/themes/use-is-dark-mode';
import authBgSvg from './auth-bg.svg';
import {useTrans} from '@ui/i18n/use-trans';
import {useSettings} from '@ui/settings/use-settings';

interface AuthPageProps {
  heading?: ReactNode;
  message?: ReactNode;
  children: ReactNode;
}
export function AuthLayout({heading, children, message}: AuthPageProps) {
  const {branding} = useSettings();
  const isDarkMode = useIsDarkMode();
  const navigate = useNavigate();

  const {trans} = useTrans();

  return (
    <main
      className="relative flex h-screen flex-col items-center overflow-y-auto bg-alt px-14 pt-70 dark:bg-none md:px-10vw"
      // style={{ backgroundImage: isDarkMode ? undefined : `url("${authBgSvg}")` }}
      // style={{
      //   backgroundImage: `url("${authBgSvg}")`,
      // }}
    >
      <div
        className="absolute inset-0 bg-repeat"
        style={{
          backgroundImage: `url("${authBgSvg}")`,
          filter: isDarkMode ? 'blur(1px)' : 'none',
          opacity: isDarkMode ? 0.2 : 'none',
        }}
      ></div>
      <img
        src={isDarkMode ? branding.logo_light : branding?.logo_dark}
        className="z-10 m-auto mb-16 block h-42 w-auto cursor-pointer"
        alt=""
        onClick={() => navigate('/')}
      />
      <div className="z-10 mx-auto w-full max-w-440 rounded-lg bg px-40 pb-32 pt-40 shadow md:shadow-xl">
        {heading && <h1 className="z-10 mb-20 text-xl">{heading}</h1>}
        {children}
      </div>
      {message && <div className="text-md z-10 mt-36 font-bold">{message}</div>}
      <AuthLayoutFooter />
    </main>
  );
}
