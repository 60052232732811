export function prettyBytes(num?: number, fractionDigits = 2): string {
  if (num == null || Number.isNaN(num)) return '';
  const neg = num < 0;
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return `${(neg ? '-' : '') + num} B`;
  }

  const exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1024)),
    units.length - 1,
  );
  num = num / Math.pow(1024, exponent);
  const unit = units[exponent];

  // If num is a whole number, show no decimals; otherwise, show fractionDigits
  const formattedNum =
    num % 1 === 0 ? num.toFixed(0) : num.toFixed(fractionDigits);

  return `${neg ? '-' : ''}${formattedNum} ${unit}`;
}
