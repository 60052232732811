import {useMutation} from '@tanstack/react-query';
import {apiClient} from '../http/query-client';
import {useTrans} from '@ui/i18n/use-trans';
import {BackendResponse} from '../http/backend-response/backend-response';
import {toast} from '@ui/toast/toast';
import {message} from '@ui/i18n/message';
import {UseFormReturn} from 'react-hook-form';
import {onFormQueryError} from '../errors/on-form-query-error';
import {useNavigate} from '../ui/navigation/use-navigate';

interface Response extends BackendResponse {}

export interface ResellerPagePayload {
  name: string;
  email: string;
  message: string;
  mobileNumber: string;
}

export function useSubmitResellerForm(
  form: UseFormReturn<ResellerPagePayload>,
) {
  const {trans} = useTrans();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (props: ResellerPagePayload) => submitContactForm(props),
    onSuccess: () => {
      toast(trans(message('Your message has been submitted.')));
      navigate('/');
    },
    onError: err => onFormQueryError(err, form),
  });
}

function submitContactForm(payload: ResellerPagePayload): Promise<Response> {
  return apiClient.post('reseller', payload).then(r => r.data);
}
