import { useProducts } from './use-products';
import { Button } from '@ui/buttons/button';
import { Trans } from '@ui/i18n/trans';
import { ForumIcon } from '@ui/icons/material/Forum';
import { Navbar } from '../../ui/navigation/navbar/navbar';
import { Link } from 'react-router-dom';
import { Footer } from '../../ui/footer/footer';
import { Fragment, useState } from 'react';
import { UpsellBillingCycle } from './find-best-price';
import { BillingCycleRadio } from './billing-cycle-radio';
import { StaticPageTitle } from '../../seo/static-page-title';
import { PricingTable } from '@common/billing/pricing-table/pricing-table';

export function PricingPage() {
  const query = useProducts('pricingPage');
  const [selectedCycle, setSelectedCycle] =
    useState<UpsellBillingCycle>('month');

  return (
    <Fragment>
      <StaticPageTitle>
        <Trans message="Pricing" />
      </StaticPageTitle>
      <Navbar
        color="bg"
        darkModeColor="transparent"
        border="border-b"
        menuPosition="pricing-table-page"
        className="bg-primary-dark text-white"
      />
      <div className="landing-container font-archivo mx-auto">
        <h1 className="mb-40 mt-30 text-center text-3xl font-semibold md:mt-60 md:text-4xl">
          <Trans message="Choose the right plan for you" />
        </h1>

        <BillingCycleRadio
          products={query.data?.products}
          selectedCycle={selectedCycle}
          onChange={setSelectedCycle}
          className="input-radio mb-24 flex justify-center"
          size="lg"
        />

        <PricingTable
          selectedCycle={selectedCycle}
          productLoader="pricingPage"
        />
        <ContactSection />
      </div>
      <Footer className="container mx-auto flex-shrink-0 px-24" />
    </Fragment>
  );
}

export function ContactSection() {
  return (
    <div className="my-20 p-24 text-center md:my-80">
      <ForumIcon size="xl" className="text-muted" />
      <div className="font-archivo my-8 font-medium md:text-xl">
        <Trans message="Do you have any questions about PRO accounts?" />
      </div>
      <div className="font-inter mb-24 mt-20 text-sm md:mt-0 md:text-base">
        <Trans message="Our support team will be happy to assist you." />
      </div>
      <Button className="btn-warning h-auto" elementType={Link} to="/contact">
        <Trans message="Contact Us" />
      </Button>
    </div>
  );
}
