import {useForm} from 'react-hook-form';
import {useEffect, useId, useState} from 'react';
import {User} from '@ui/types/user';
import {AccountSettingsPanel} from '../account-settings-panel';
import {Button} from '@ui/buttons/button';
import {Form} from '@ui/forms/form';
import {FormTextField} from '@ui/forms/input-field/text-field/text-field';
import {
  getCountries,
  getStates,
  skip_counts,
  useUpdateAccountDetails,
} from './update-account-details';
import {Trans} from '@ui/i18n/trans';
import {useUploadAvatar} from '../avatar/upload-avatar';
import {useRemoveAvatar} from '../avatar/remove-avatar';
import {FormImageSelector} from '@common/uploads/components/image-selector';
import {FileUploadProvider} from '@common/uploads/uploader/file-upload-provider';
import {AccountSettingsId} from '@common/auth/ui/account-settings/account-settings-sidenav';
import {FormSelect} from '@ui/forms/select/select';
import {Item} from '@ui/forms/listbox/item';
import {useNavigate} from 'react-router-dom';
import {FullPageLoader} from '@ui/progress/full-page-loader';

interface Props {
  user: User;
}

export function BasicInfoPanel({user}: Props) {
  const navigate = useNavigate();

  const uploadAvatar = useUploadAvatar({user});
  const removeAvatar = useRemoveAvatar({user});
  const formId = useId();

  const [countries, setCountries] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [isShowState, setIsShowState] = useState<boolean>(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getCountries();
        const responseState = await getStates();
        setCountries(response?.data || []);
        setStates(responseState?.data || []);
        let filterCountry = response?.data.filter((item: any) => {
          return item.id === user.country_id;
        });
        if (filterCountry.length > 0 && filterCountry[0].name === 'India') {
          let filterStates = responseState?.data.filter((item: any) => {
            return item.country_id === filterCountry[0].id;
          });
          setStates(filterStates);
          setIsShowState(true);
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    }

    fetchData();
  }, []);

  const form = useForm<Partial<Omit<User, 'subscriptions'>>>({
    defaultValues: {
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      image: user.image,
      country_id: user.country_id || 101,
      state_id: user.state_id || 0,
      email: user.email,
    },
  });
  const updateDetails = useUpdateAccountDetails(user.id, form);
  const handleAccountSettingsCount = () => {
    skip_counts({user_id: user.id});
    navigate('/drive');
  };

  const handleChangeCountry = (selectedCountryId: any) => {
    const selectedCountry = countries.find(
      item => item.id === selectedCountryId,
    );
    if (selectedCountry?.name === 'India') {
      setIsShowState(true);
      let filterStates = states.filter(item => {
        return item.country_id === selectedCountryId;
      });
      setStates(filterStates);
    } else {
      setIsShowState(false);
    }
  };

  return (
    <AccountSettingsPanel
      id={AccountSettingsId.AccountDetails}
      title={<Trans message="Update name and profile image" />}
      actions={
        <>
          <Button
            type="submit"
            variant="flat"
            color="primary"
            form={formId}
            disabled={updateDetails.isPending || !form.formState.isValid}
          >
            <Trans message="Save" />
          </Button>
          <Button
            type="submit"
            variant="flat"
            className="ml-8"
            onClick={() => handleAccountSettingsCount()}
          >
            <Trans message="Skip" />
          </Button>
        </>
      }
    >
      {countries.length > 0 ? (
        <Form
          form={form}
          className="flex flex-col items-center gap-40 md:flex-row md:gap-80"
          onSubmit={newDetails => {
            updateDetails.mutate(newDetails, {
              onSuccess: () => {
                navigate('/drive');
              },
            });
          }}
          id={formId}
        >
          <div className="w-full flex-auto">
            <FormTextField
              className="mb-24"
              name="email"
              label={<Trans message="Email" />}
              readOnly
            />
            <FormTextField
              className="mb-24"
              name="first_name"
              label={<Trans message="First name" />}
            />
            <FormTextField
              name="last_name"
              className="mb-24"
              label={<Trans message="Last name" />}
            />
            <FormSelect
              label={<Trans message="Country" />}
              name="country_id"
              selectionMode="single"
              className="mb-24"
              onSelectionChange={(selected: any) =>
                handleChangeCountry(selected)
              } // 🔹 Use onSelectionChange instead of onChange
            >
              {countries.map((item: any) => (
                <Item key={item.id} value={item.id}>
                  <Trans message={item.name} />
                </Item>
              ))}
            </FormSelect>

            {isShowState && (
              <FormSelect
                label={<Trans message="State" />}
                name="state_id"
                selectionMode="single"
                className="mb-20"
              >
                {states.map((item: any) => (
                  <Item key={item.id} value={item.id}>
                    <Trans message={item.name} />
                  </Item>
                ))}
              </FormSelect>
            )}
          </div>
          <FileUploadProvider>
            <FormImageSelector
              className="md:mr-80"
              variant="avatar"
              previewSize="w-90 h-90"
              showRemoveButton
              name="image"
              diskPrefix="avatars"
              label={<Trans message="Profile image" />}
              onChange={url => {
                if (url) {
                  uploadAvatar.mutate({url});
                } else {
                  removeAvatar.mutate();
                }
              }}
            />
          </FileUploadProvider>
        </Form>
      ) : (
        <FullPageLoader />
      )}
    </AccountSettingsPanel>
  );
}
