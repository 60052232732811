import {Trans} from '@ui/i18n/trans';
import {Navbar} from '@common/ui/navigation/navbar/navbar';
import {Form} from '@ui/forms/form';
import {useForm} from 'react-hook-form';
import {
  ResellerPagePayload,
  useSubmitResellerForm,
} from './use-submit-reseller-form';
import {FormTextField} from '@ui/forms/input-field/text-field/text-field';
import {Button} from '@ui/buttons/button';
import {useRecaptcha} from '../recaptcha/use-recaptcha';
import {StaticPageTitle} from '../seo/static-page-title';
import {Footer} from '@common/ui/footer/footer';
import {FullPageLoader} from '@ui/progress/full-page-loader';

export function ResellerPage() {
  const form = useForm<ResellerPagePayload>();
  const submitForm = useSubmitResellerForm(form);
  const {verify, isVerifying} = useRecaptcha('contact');

  return (
    <div
      className="flex min-h-screen flex-col bg-alt"
      style={{
        backgroundImage: "url('/images/LandingImg/ctamask.png')",
      }}
    >
      <StaticPageTitle>
        <Trans message="Reseller" />
      </StaticPageTitle>
      <Navbar className="sticky top-0 flex-shrink-0" menuPosition="reseller" />
      {submitForm.isPending ? (
        <FullPageLoader />
      ) : (
        <div className="container mx-auto flex flex-auto items-center justify-center p-24 md:p-40">
          <div className="max-w-620 rounded-lg bg p-24 shadow md:shadow-xl">
            <h1 className="text-2xl">
              <Trans message="Reseller" />
            </h1>
            <p className="mb-30 mt-4 text-sm">
              <Trans message="Please use the form below to send us a message and we'll get back to you as soon as possible." />
            </p>
            <Form
              form={form}
              onSubmit={async value => {
                const isValid = await verify();
                if (isValid) {
                  submitForm.mutate(value);
                }
              }}
            >
              <FormTextField
                label={<Trans message="Name" />}
                name="name"
                required
                className="mb-20"
              />
              <FormTextField
                label={<Trans message="Email" />}
                name="email"
                required
                type="email"
                className="mb-20"
              />
              <FormTextField
                label={<Trans message="Mobile Number" />}
                name="mobileNumber"
                required
                type="number"
                className="mb-20"
              />
              <FormTextField
                label={<Trans message="Message" />}
                name="message"
                required
                inputElementType="textarea"
                className="mb-20"
                rows={8}
              />
              <div className="flex w-full justify-between">
                <Button
                  type="submit"
                  variant="flat"
                  color="primary"
                  disabled={submitForm.isPending || isVerifying}
                >
                  <Trans message="Send Message" />
                </Button>
                <Button
                  variant="flat"
                  color="chip"
                  onClick={() => history.back()}
                >
                  <Trans message="Back" />
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
      <Footer className="container mx-auto flex-shrink-0 px-24" />
    </div>
  );
}
