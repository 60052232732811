import {Price} from '../price';

export type UpsellBillingCycle = 'month' | 'year';

export function findBestPrice(
  token: UpsellBillingCycle,
  prices: Price[],
): Price | undefined {
  if (token === 'month') {
    const match = findMonthlyPrice(prices);
    if (match) return match;
  }

  if (token === 'year') {
    const match = findYearlyPrice(prices);
    if (match) return match;
  }

  return prices[0];
}

function findYearlyPrice(prices: Price[]) {
  return prices.find(price => {
    if (price.interval === 'month' && price.interval_count >= 12) {
      return price;
    }
    if (price.interval === 'year' && price.interval_count >= 1) {
      return price;
    }
  });
}

function findMonthlyPrice(prices: Price[]) {
  return prices.find(price => {
    if (price.interval === 'day' && price.interval_count >= 30) {
      return price;
    }
    if (price.interval === 'month' && price.interval_count >= 1) {
      return price;
    }
  });
}
