import { Button } from '@ui/buttons/button';
import { Trans } from '@ui/i18n/trans';
import { Form } from '@ui/forms/form';
import { Fragment, useEffect, useRef, useState } from 'react';
import { DefaultMetaTags } from '@common/seo/default-meta-tags';
import { CheckCircleOutlineIcon } from '@ui/icons/material/CheckCircleOutline';
import { CloudIcon } from '@ui/icons/material/Cloud';
import { DevicesIcon } from '@ui/icons/material/Devices';
import { ShareIcon } from '@ui/icons/material/Share';
import { CloudUploadIcon } from '@ui/icons/material/CloudUpload';
import { BillingCycleRadio } from '@common/billing/pricing-table/billing-cycle-radio';
import { PricingTable } from '@common/billing/pricing-table/pricing-table';
import { UpsellBillingCycle } from '@common/billing/pricing-table/find-best-price';
import { useProducts } from '@common/billing/pricing-table/use-products';
import { FacebookIcon } from '@ui/icons/social/facebook';
import { InstagramIcon } from '@ui/icons/social/instagram';
import { YoutubeIcon } from '@ui/icons/social/youtube';
import {
  ContactPagePayload,
  useSubmitContactForm,
} from '@common/contact/use-submit-contact-form';
import { useForm } from 'react-hook-form';
import { Dialog } from '@ui/overlays/dialog/dialog';
import { DialogBody } from '@ui/overlays/dialog/dialog-body';
import { DialogHeader } from '@ui/overlays/dialog/dialog-header';
import { DialogTrigger } from '@ui/overlays/dialog/dialog-trigger';
import { FormTextField } from '@ui/forms/input-field/text-field/text-field';
import { useRecaptcha } from '@common/recaptcha/use-recaptcha';
import { Navbar } from '@common/ui/navigation/navbar/navbar';
import { FullPageLoader } from '@ui/progress/full-page-loader';
import { Accordion, AccordionItem } from '@ui/accordion/accordion';
import {
  ResellerPagePayload,
  useSubmitResellerForm,
} from '@common/reseller/use-submit-reseller-form';
import { useAdminSettings } from '@common/admin/settings/requests/use-admin-settings';
import { useAuth } from '@common/auth/use-auth';

export function LandingPage() {
  const query = useProducts('pricingPage');
  const [selectedCycle, setSelectedCycle] =
    useState<UpsellBillingCycle>('month');

  const { verify, isVerifying } = useRecaptcha('contact');

  const form = useForm<ResellerPagePayload>();
  const submitForm = useSubmitResellerForm(form);
  const { isLoggedIn } = useAuth();

  const [subscribeEmail, setSubscribeEmail] = useState('');
  const [resellerLoader, setResellerLoader] = useState(false);
  const [error, setError] = useState('');
  const [expandedValues, setExpandedValues] = useState([0]);
  const validateEmail = (email: any) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubmit = () => {
    if (!validateEmail(subscribeEmail)) {
      setError('Please enter a valid email address.');
    } else {
      setError('');
      alert(`Subscribed with: ${subscribeEmail}`); // Replace with API call
    }
  };

  // const TawkTo = () => {
  //   useEffect(() => {
  //     const Tawk_API = (window as any).Tawk_API || {};
  //     const Tawk_LoadStart = new Date();

  //     const script = document.createElement('script');
  //     script.async = true;
  //     script.src = 'https://embed.tawk.to/679a04db3a8427326076ac96/1iioq5v0g';
  //     script.charset = 'UTF-8';
  //     script.setAttribute('crossorigin', '*');

  //     document.body.appendChild(script);

  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }, []);

  //   return null; // This component doesn't render anything
  // };

  return (
    <Fragment>
      {/* {!isLoggedIn && <TawkTo />} */}
      <DefaultMetaTags />

      <Navbar
        color="transparent"
        className="fixed top-0 z-50 w-full flex-shrink-0 border-none bg-primary-dark shadow-lg"
        menuPosition="homepage-navbar"
      />

      <section className="hero-section relative overflow-hidden bg-primary-dark">
        <div
          className={`landing-container z-10 flex flex-col items-center justify-between pb-40 pt-100 lg:flex-row`}
        >
          {/* Left Content */}
          <div className="w-full text-center text-white lg:w-1/2 lg:text-left">
            <div className="relative space-y-16">
              <div className="animate-fade-down mb-16 flex items-center justify-center lg:justify-start">
                <div className="bg-light font-inter flex items-center gap-4 rounded-md p-6">
                  <img
                    src="/images/logo-light.png"
                    className="h-auto w-50"
                    alt="Logo"
                  />
                  <span className="color-primary2 text-sm font-normal">
                    Manavtricks Cloud Storage
                  </span>
                </div>
              </div>
              <h1 className="animate-fade-down font-archivo relative text-3xl font-semibold md:text-4xl lg:text-5xl">
                <div className="absolute -left-30 -top-20 hidden lg:block">
                  <img
                    src="/images/LandingImg/heroshape3.png"
                    alt="shape"
                    className="w-38"
                  />
                </div>
                Keep Your <span className="color-primary2">Files, Folders</span>{' '}
                and <span className="color-primary2">Memories</span> Safe and
                Ready to Share.
              </h1>
              <p className="animate-fade-down font-inter">
                Everything you or your business needs to work efficiently, all
                in one place. Upload, manage, and access your files from any
                device, anywhere.
              </p>
              <div className="flex justify-center lg:justify-start">
                <a href="/register" className="btn-bordered">
                  SIGN UP FOR FREE
                </a>
              </div>
            </div>
          </div>

          {/* Right Image */}
          <div className="mt-40 hidden w-full md:block lg:mt-0 lg:w-1/2">
            <div className="relative">
              <img
                src="/images/LandingImg/heroimg.png"
                alt="Banner"
                className="mx-auto w-full max-w-sm object-cover md:max-w-lg lg:max-w-full"
              />
            </div>
          </div>
        </div>

        {/* Decorative Elements */}
        <div>
          <img
            src="/images/LandingImg/heroshape.png"
            alt="banner mask"
            className="w-full"
          />
        </div>
        <div className="absolute -bottom-80 left-0 hidden md:block">
          <img
            src="/images/LandingImg/background.png"
            className="h-780 w-auto md:h-580"
            alt="shape"
          />
        </div>
        <div className="absolute -bottom-80 left-0 hidden md:block">
          <img
            src="/images/LandingImg/heroshape1.png"
            className="h-640 w-auto"
            alt="shape"
          />
        </div>
        <div className="absolute right-24 top-100 hidden lg:block">
          <img
            src="/images/LandingImg/heroshape2.png"
            alt="shape"
            className="w-32 md:w-48"
          />
        </div>
      </section>

      <section className="about-section relative overflow-hidden bg-white py-40 text-black lg:pb-0 lg:pt-100">
        <div className="landing-container z-10 flex flex-col items-center justify-between gap-8 px-4 md:px-8 lg:flex-row">
          {/* Left Section */}
          <div className="relative flex w-full justify-center lg:w-1/2 lg:justify-end">
            <div className="relative max-w-sm md:max-w-md lg:max-w-full">
              <div className="absolute -bottom-10 -right-10 hidden lg:block">
                <img
                  src="/images/LandingImg/shape3.png"
                  alt="shape"
                  className="w-16 md:w-20"
                />
              </div>
              <img
                src="/images/LandingImg/aboutimg.png"
                alt="about"
                className="w-full lg:pr-10"
              />
            </div>
          </div>

          {/* Right Content */}
          <div className="w-full space-y-16 lg:w-1/2">
            <h2 className="animate-fade-down font-archivo text-3xl font-bold md:text-4xl lg:text-5xl">
              About Our <span className="color-primary-gradient">Journey</span>
            </h2>
            <p className="color-gray font-inter animate-fade-down">
              Learn more about our journey and our commitment to providing
              secure cloud storage solutions. We ensure your files are safe and
              always available for you.
            </p>

            {/* Checkpoints Section */}
            <div className="font-inter animate-fade-up -mx-2 flex flex-wrap space-y-4">
              <div className="mb-4 flex w-full items-center px-2 md:w-1/2">
                <span className="text-green-500 mr-4">
                  <CheckCircleOutlineIcon color="green" />
                </span>
                <span className="text-gray-800">Secure Storage System</span>
              </div>
              <div className="mb-4 flex w-full items-center px-2 md:w-1/2">
                <span className="text-green-500 mr-4">
                  <CheckCircleOutlineIcon color="green" />
                </span>
                <span className="text-gray-800">24/7 Customer Support</span>
              </div>
              <div className="mb-4 flex w-full items-center px-2 md:w-1/2">
                <span className="text-green-500 mr-4">
                  <CheckCircleOutlineIcon color="green" />
                </span>
                <span className="text-gray-800">High-Quality Results</span>
              </div>
              <div className="mb-4 flex w-full items-center px-2 md:w-1/2">
                <span className="text-green-500 mr-4">
                  <CheckCircleOutlineIcon color="green" />
                </span>
                <span className="text-gray-800">Easy Accessibility</span>
              </div>
            </div>

            {/* Author Profile */}
            <div className="flex flex-wrap items-center gap-8 pt-8 lg:pt-24">
              <div className="relative z-10">
                <img
                  src="/images/LandingImg/aboutavtar.png"
                  alt="Author"
                  className="h-auto w-48 rounded-full"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Elements */}
        <div className="absolute -bottom-40 left-0 hidden lg:block">
          <img
            src="/images/LandingImg/background.png"
            className="h-auto w-320"
            alt="shape"
          />
        </div>
        <div className="absolute right-12 top-5 hidden lg:block">
          <img
            src="/images/LandingImg/shape1.png"
            alt="shape"
            className="w-64"
          />
        </div>
        <div className="absolute bottom-40 left-8 hidden lg:block">
          <img
            src="/images/LandingImg/shape2.png"
            alt="shape"
            className="w-64"
          />
        </div>
      </section>

      <section
        id="Features"
        className="services-section relative overflow-hidden bg-white py-40 text-black lg:pb-100 lg:pt-100"
      >
        <div className="landing-container z-10 flex flex-wrap items-center justify-between">
          {/* Left Content */}
          <div className="mb-16 w-full space-y-16 pr-0 lg:mb-0 lg:w-1/2 lg:pr-10">
            {/* Section Header */}
            <div className="animate-fade-down font-archivo text-3xl font-bold md:text-4xl lg:text-5xl">
              Check out Our <span className="color-primary-gradient">Wide</span>{' '}
              Range of Features
            </div>
            <p className="color-gray font-inter animate-fade-down">
              Access your files anytime, anywhere with top-notch security and
              seamless synchronization across devices. Enjoy the storing data at
              any time, it's 100% safe and secure. Benefit from competitive
              pricing and round-the-clock support to ensure your experience is
              smooth and reliable.
            </p>

            {/* Checkpoints Section */}
            <div className="font-inter animate-fade-up -mx-4 flex flex-wrap space-y-4">
              <div className="mb-4 flex w-full items-center px-4 md:mb-0 md:w-1/2">
                <span className="mr-4">
                  <CheckCircleOutlineIcon color="green" />
                </span>
                <span className="text-gray-800">Anytime Stored Data</span>
              </div>
              <div className="mb-4 flex w-full items-center px-4 md:mb-0 md:w-1/2">
                <span className="mr-4">
                  <CheckCircleOutlineIcon color="green" />
                </span>
                <span className="text-gray-800">100% Safe & Secure</span>
              </div>
              <div className="flex w-full items-center px-4 md:w-1/2">
                <span className="mr-4">
                  <CheckCircleOutlineIcon color="green" />
                </span>
                <span className="text-gray-800">Competitive Pricing</span>
              </div>
              <div className="flex w-full items-center px-4 md:w-1/2">
                <span className="mr-4">
                  <CheckCircleOutlineIcon color="green" />
                </span>
                <span className="text-gray-800">24 Hours Support</span>
              </div>
            </div>

            {/* Button */}
            <div className="flex justify-center lg:justify-start">
              <a href="#" className="btn-warning">
                <span>Explore More</span>
              </a>
            </div>
          </div>

          {/* Right Content */}
          <div className="mt-40 w-full space-y-8 md:space-y-16 lg:mt-0 lg:w-1/2">
            {/* Feature Box 1 */}
            <div className="feature-box1 bg-light-primary flex cursor-default flex-col items-center gap-8 rounded-xl border-primary p-16 md:-translate-x-16 md:flex-row lg:translate-x-0 lg:p-24">
              <div className="flex items-center justify-center rounded-lg border-primary">
                <CloudIcon className="h-64 w-64 p-12 md:h-48 md:w-48 lg:h-64 lg:w-64" />
              </div>
              <div className="space-y-4 text-center md:text-start">
                <h4 className="color-primary font-archivo text-xl font-semibold md:text-2xl">
                  Store Any File
                </h4>
                <p className="color-gray font-inter">
                  You can store photos, stories, designs, drawings, recordings,
                  videos, and more, with your{' '}
                  <span className="color-primary font-semibold">
                    first 25 GB of storage free.
                  </span>
                </p>
              </div>
            </div>

            {/* Feature Box 2 */}
            <div className="feature-box2 bg-light-primary2 flex transform cursor-default flex-col items-center gap-8 rounded-xl border-black p-16 md:translate-x-16 md:flex-row lg:translate-x-24 lg:p-24">
              <div className="flex items-center justify-center rounded-lg border-black">
                <DevicesIcon className="h-64 w-64 p-12 md:h-48 md:w-48 lg:h-64 lg:w-64" />
              </div>
              <div className="space-y-4 text-center md:text-start">
                <h4 className="color-black font-archivo text-xl font-semibold md:text-2xl">
                  Access Anywhere
                </h4>
                <p className="color-gray font-inter">
                  You can easily access your files in Manavtricks Cloud Storage
                  from any smartphone, tablet, or computer.
                </p>
              </div>
            </div>

            {/* Feature Box 3 */}
            <div className="feature-box3 bg-light-warning flex cursor-default flex-col items-center gap-8 rounded-xl border-warning p-16 md:-translate-x-16 md:flex-row lg:translate-x-0 lg:p-24">
              <div className="flex items-center justify-center rounded-lg border-warning">
                <ShareIcon className="h-64 w-64 p-12 md:h-48 md:w-48 lg:h-64 lg:w-64" />
              </div>
              <div className="space-y-4 text-center md:text-start">
                <h4 className="color-warning font-archivo text-xl font-semibold md:text-2xl">
                  Share Files & Folders
                </h4>
                <p className="color-gray font-inter">
                  Share photos, videos, documents, and entire folders that can
                  be easily reached by family, friends, and colleagues.
                </p>
              </div>
            </div>

            {/* Feature Box 4 */}
            <div className="feature-box4 bg-light-primary flex transform cursor-default flex-col items-center gap-8 rounded-xl border-primary p-16 md:translate-x-16 md:flex-row lg:translate-x-24 lg:p-24">
              <div className="flex items-center justify-center rounded-lg border-primary">
                <CloudUploadIcon className="h-64 w-64 p-12 md:h-48 md:w-48 lg:h-64 lg:w-64" />
              </div>
              <div className="space-y-4 text-center md:text-start">
                <h4 className="color-primary font-archivo text-xl font-semibold md:text-2xl">
                  No Bandwidth Limit
                </h4>
                <p className="color-gray font-inter">
                  No upload bandwidth limit, no upload file size limit, making
                  your file storage effortless and efficient for all your needs.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Elements */}
        <div className="absolute -bottom-240 right-0 hidden lg:block">
          <img
            src="/images/LandingImg/Backgroundright.png"
            className="h-auto w-320"
            alt="shape"
          />
        </div>
        <div className="absolute bottom-40 right-32 hidden lg:block">
          <img src="/images/LandingImg/blumb.png" alt="shape" />
        </div>
        <div className="absolute bottom-80 left-400 hidden lg:block">
          <img src="/images/LandingImg/dimond.png" alt="shape" />
        </div>
      </section>

      <section
        id="atmanirbharbharat"
        className="overview-section relative overflow-hidden bg-primary-dark py-40 text-white lg:py-100"
      >
        <div className="landing-container z-10 flex flex-wrap items-center justify-between md:gap-24 lg:gap-0">
          {/* Left Section */}
          <div className="relative mb-10 w-full lg:mb-0 lg:w-3/5">
            <div className="relative">
              <img
                src="/images/LandingImg/model.png"
                alt="about"
                className="mx-auto w-full max-w-lg lg:max-w-none lg:pr-40"
              />
            </div>
          </div>

          {/* Right Content */}
          <div className="w-full space-y-16 text-center lg:w-2/5">
            <h2 className="animate-fade-down font-archivo animate-fade-down text-xl font-semibold text-white md:text-2xl lg:text-2xl">
              Your Data is 100%{' '}
              <span className="color-primary-gradient">SAFE & SECURE</span>,
              Encrypted And Stored Locally Within{' '}
              <span
                className="color-saffron"
                style={{ fontFamily: 'Kaushan Script,serif' }}
              >
                IN
              </span>
              <span style={{ fontFamily: 'Kaushan Script,serif' }}>D</span>
              <span
                className="color-green"
                style={{ fontFamily: 'Kaushan Script,serif' }}
              >
                IA
              </span>
            </h2>

            <h2 className="animate-fade-up text-xl font-semibold md:text-2xl lg:text-3xl">
              <span
                className="color-saffron"
                style={{ fontFamily: 'Kaushan Script,serif' }}
              >
                FOR INDIA
              </span>{' '}
              <br />{' '}
              <span
                className="text-white"
                style={{ fontFamily: 'Kaushan Script,serif' }}
              >
                FOR INDIANS
              </span>{' '}
              <br />{' '}
              <span
                className="color-green"
                style={{ fontFamily: 'Kaushan Script,serif' }}
              >
                SAFELY STORED WITHIN INDIA
              </span>
            </h2>

            <div className="flex justify-center">
              <a href="/register" className="btn-default">
                <span>SIGN UP FOR FREE</span>
              </a>
            </div>
          </div>
        </div>

        {/* Elements */}
        <div className="absolute -bottom-100 left-0 hidden md:block">
          <img
            src="/images/LandingImg/background.png"
            className="h-640 w-auto"
            alt="shape"
          />
        </div>
        <div className="absolute -top-264 right-0 hidden md:block">
          <img
            src="/images/LandingImg/Backgroundright.png"
            className="h-640 w-auto"
            alt="shape"
          />
        </div>
        <div className="absolute left-10 top-10 hidden md:left-40 md:top-40 lg:block">
          <img src="/images/LandingImg/shape3.png" alt="shape" />
        </div>
        <div className="absolute bottom-10 right-10 hidden md:bottom-40 md:right-40 lg:block">
          <img src="/images/LandingImg/shape3.png" alt="shape" />
        </div>
      </section>

      <section
        id="Pricing"
        className="pricing-section relative overflow-hidden bg-white py-40 text-black lg:py-100"
      >
        <div className="landing-container z-10">
          <div className="mx-auto max-w-xl space-y-16 text-center">
            <h2 className="animate-fade-down font-archivo text-3xl font-bold md:text-4xl lg:text-5xl">
              Our <span className="color-primary-gradient">Premium</span> Plans
            </h2>
            <p className="color-gray font-inter animate-fade-down">
              Choose from flexible pricing plans designed to meet your unique
              needs. Enjoy competitive pricing with no hidden fees, 100% secure
              storage, and seamless data access.
            </p>
          </div>

          <BillingCycleRadio
            products={query.data?.products}
            selectedCycle={selectedCycle}
            onChange={setSelectedCycle}
            className="input-radio animate-fade-up mb-24 mt-40 flex justify-center"
            size="lg"
          />

          <PricingTable
            selectedCycle={selectedCycle}
            productLoader="pricingPage"
            className="animate-fade-down"
          />

          {/* Premium Section */}
          <div
            className="premium-bg animate-fade-down bg-light-primary relative mt-40 flex flex-col items-center justify-between space-y-8 overflow-hidden rounded-2xl border-primary p-16 shadow-lg md:flex-row md:space-y-0 md:p-32"
            style={{
              backgroundImage:
                "url('/images/LandingImg/ctamask.png'), url('/images/LandingImg/ctashape.png')",
              backgroundSize: 'contain, contain',
              backgroundRepeat: 'no-repeat, no-repeat',
              backgroundPosition: 'left center, right center',
            }}
          >
            {/* Premium Tag */}
            <div className="font-archivo absolute -left-54 top-16 w-160 rotate-[-45deg] transform rounded bg-warning text-center text-sm font-medium text-black md:-left-56 lg:-left-50 lg:top-20">
              Premium
            </div>

            {/* Icon and Text */}
            <div className="flex flex-col items-center gap-8 space-y-8 md:flex-row md:space-x-8 md:space-y-0">
              <img
                src="/images/LandingImg/cta-icon.png"
                className="color-primary rounded-lg bg-white p-16"
              />
              <div className="text-center md:text-left">
                <h2 className="font-archivo text-3xl font-bold">
                  5 TB or More?
                </h2>
                <span className="color-primary font-archivo text-2xl font-semibold">
                  ₹0.15/GB{' '}
                  <span className="text-lg font-normal text-black">
                    /Monthly
                  </span>
                </span>
                <p className="font-inter mt-8 flex items-start justify-center gap-4 text-sm md:justify-start">
                  <CheckCircleOutlineIcon size="sm" /> 100% Safe & Secure with
                  End-to-End Encryption
                </p>
                <p className="font-inter mt-1 flex items-start justify-center gap-4 text-sm md:justify-start">
                  <CheckCircleOutlineIcon size="sm" /> Calculate the cost or
                  Reach out to us
                </p>
              </div>
            </div>

            {/* Buttons */}
            <div className="flex flex-row flex-wrap gap-8 sm:flex-col lg:flex-row justify-center">
              <div className="flex justify-center">
                {CalculateDialogTrigger()}
              </div>

              <div className="mt-0 flex justify-center">
                <a href="/contact" className="btn-black">
                  <span>Contact Us</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="FAQ-section bg-light-primary2 relative overflow-hidden py-40 text-black lg:py-100">
        <div className="landing-container z-10 flex flex-wrap items-center justify-between">
          {/* Left Content */}
          <div className="w-full px-6 md:px-12 lg:w-1/2 lg:px-0">
            <div className="animate-fade-in-up space-y-16 pb-24 text-start">
              <h2 className="animate-fade-down font-archivo text-3xl font-bold md:text-4xl lg:text-5xl">
                The <span className="color-primary-gradient">Quick</span>{' '}
                Reference Guide for All Your Questions
              </h2>
              <p className="color-gray font-inter animate-fade-down">
                Whether you're looking for information on file uploads, storage
                limits, data security, or syncing across devices, we're here to
                help. Explore the questions below for quick solutions and tips.
              </p>
            </div>
            <div className="accordion animate-fade-up space-y-16">
              <Accordion
                variant="outline"
                expandedValues={expandedValues}
                onExpandedChange={values => {
                  setExpandedValues(values as number[]);
                }}
              >
                <AccordionItem
                  label={
                    <Trans message={'What is Manavtricks Cloud Storage?'} />
                  }
                  labelClassName={`${expandedValues.includes(0) && 'color-primary'
                    } text-[16px] font-semibold`}
                  bodyClassName="max-h-240 bg-light-primary  overflow-y-auto"
                  value={0}
                >
                  Manavtricks Cloud Storage is a secure platform for storing and
                  accessing your files anytime, anywhere.
                </AccordionItem>
                <AccordionItem
                  label={<Trans message={'How much storage is free?'} />}
                  labelClassName={`${expandedValues.includes(1) && 'color-primary'
                    } text-[16px] font-semibold`}
                  bodyClassName="max-h-240 bg-light-primary overflow-y-auto"
                  value={1}
                >
                  New users receive 25 GB of free storage to get started with
                  our cloud service.
                </AccordionItem>
                <AccordionItem
                  label={<Trans message={'Can I share files with others?'} />}
                  labelClassName={`${expandedValues.includes(2) && 'color-primary'
                    } text-[16px] font-semibold`}
                  bodyClassName="max-h-240 bg-light-primary overflow-y-auto"
                  value={2}
                >
                  Yes, you can easily share photos, videos, documents, and even
                  entire folders with others.
                </AccordionItem>
                <AccordionItem
                  label={
                    <Trans
                      message={
                        'What if I want more than 2 TB of storage. Do you have plans for higher storage?'
                      }
                    />
                  }
                  labelClassName={`${expandedValues.includes(3) && 'color-primary'
                    } text-[16px] font-semibold`}
                  bodyClassName="max-h-240 bg-light-primary overflow-y-auto"
                  value={3}
                >
                  Yes, we have. Please contact us for more information.
                </AccordionItem>
              </Accordion>
            </div>
          </div>

          {/* Right Image */}
          <div className="hidden w-full px-6 md:px-12 lg:block lg:w-1/2 lg:px-0">
            <div className="relative">
              <img
                src="/images/LandingImg/faq-img.png"
                alt="img"
                className="w-full lg:w-auto lg:pl-40"
              />
            </div>
          </div>
        </div>

        {/* Elements */}
        <div className="absolute right-0 top-0 hidden lg:block">
          <img src="/images/LandingImg/Ellipse.png" alt="shape" />
        </div>
        <div className="absolute bottom-80 right-400 hidden lg:block">
          <img src="/images/LandingImg/redlight.png" alt="shape" />
        </div>
      </section>

      <section className="seller-section relative overflow-hidden bg-white py-40 text-black lg:py-100">
        <div className="landing-container z-10 flex flex-wrap items-center justify-between">
          {/* Left Section */}
          <div className="relative hidden w-full lg:block lg:w-1/2">
            <div className="relative">
              <img
                src="/images/LandingImg/aboutimg.png"
                alt="about"
                className="w-full lg:w-auto lg:pr-40"
              />
            </div>
          </div>

          {/* Right Content - Contact Form */}
          <div className="animate-fade-down w-full px-6 md:px-12 lg:w-1/2 lg:px-0">
            <div className="space-y-16 rounded-xl border-1 border-primary/30 bg-white p-16 md:p-24">
              <h1 className="animate-fade-down font-archivo text-3xl font-bold md:text-4xl lg:text-5xl">
                <span className="color-primary-gradient">Reseller</span> Partner
              </h1>
              <p className="color-gray font-inter">
                Please use the form below to send us a message and we'll get
                back to you as soon as possible.
              </p>
              {resellerLoader ? (
                <FullPageLoader />
              ) : (
                <Form
                  form={form}
                  onSubmit={async value => {
                    const isValid = await verify();
                    if (isValid) {
                      handleResellerSubmit(value);
                    }
                  }}
                >
                  <FormTextField
                    label={<Trans message="Name" />}
                    name="name"
                    style={{ color: 'black' }}
                    required
                    className="mb-20"
                  />
                  <FormTextField
                    label={<Trans message="Email" />}
                    style={{ color: 'black' }}
                    name="email"
                    required
                    type="email"
                    className="mb-20"
                  />
                  <FormTextField
                    label={<Trans message="Mobile Number" />}
                    style={{ color: 'black' }}
                    name="mobileNumber"
                    required
                    type="number"
                    className="mb-20"
                  />
                  <FormTextField
                    label={<Trans message="Message" />}
                    style={{ color: 'black' }}
                    name="message"
                    required
                    inputElementType="textarea"
                    className="mb-20"
                    rows={4}
                  />
                  <Button
                    type="submit"
                    className="btn-warning h-auto"
                  // disabled={submitForm.isPending || isVerifying}
                  >
                    <Trans message="Send Message" />
                  </Button>
                </Form>
              )}
            </div>
          </div>
        </div>

        {/* Elements */}
        <div className="absolute -bottom-80 left-0 hidden lg:block">
          <img
            src="/images/LandingImg/background.png"
            className="h-auto w-320"
            alt="shape"
          />
        </div>
        <div className="absolute right-50 top-5 hidden lg:block">
          <img src="/images/LandingImg/redlight.png" alt="shape" />
        </div>
        <div className="absolute bottom-50 left-20 hidden lg:block">
          <img src="/images/LandingImg/redlight.png" alt="shape" />
        </div>
      </section>

      <section className="Newsletter-section bg-light-primary relative overflow-hidden py-40 text-black lg:py-100">
        <div className="landing-container z-10">
          {/* Newsletter wrapper */}
          <div className="mx-auto max-w-xl space-y-16 text-center">
            <h2 className="animate-fade-down font-archivo text-3xl font-bold md:text-4xl lg:text-5xl">
              <span className="color-primary-gradient">Subscribe</span> to
              Newsletters and Get News.
            </h2>
            <p className="color-gray font-inter animate-fade-down">
              Sign up for updates and stay informed about the latest
              developments and be a part of our community and get the latest
              news and insights.
            </p>

            {/* Form */}
            <div className="animate-fade-up relative pb-56 md:pb-0">
              <div className="border-primary-input mx-auto flex w-full max-w-xl flex-col space-y-8 rounded-xl bg-white p-4 md:flex-row md:space-y-0 md:p-16 md:pr-140">
                <input
                  type="email"
                  placeholder="Enter your email address"
                  className="font-inter z-10 w-full p-8 focus:outline-none md:p-0 md:pl-4"
                  value={subscribeEmail}
                  onChange={e => setSubscribeEmail(e.target.value)}
                />
              </div>
              <div className="absolute mt-8 flex w-full justify-center md:right-8 md:top-8 md:mt-0 md:justify-end">
                <button className="btn-warning" onClick={handleSubmit}>
                  <span>Subscribe</span>
                </button>
              </div>
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}

            {/* Features Section */}
            <div className="items-left md:item-center color-gray font-inter mt-8 flex flex-col space-y-4 text-sm md:flex-row md:justify-center md:space-x-16 md:space-y-0">
              <div className="flex items-center space-x-2">
                <CheckCircleOutlineIcon color="green" />
                <span>Anytime Stored Data</span>
              </div>
              <div className="flex items-center space-x-2">
                <CheckCircleOutlineIcon color="green" />
                <span>100% Safe & Secure</span>
              </div>
              <div className="flex items-center space-x-2">
                <CheckCircleOutlineIcon color="green" />
                <span>24 Hours Supports</span>
              </div>
            </div>
          </div>

          {/* Elements */}
          <div className="absolute bottom-80 left-20 hidden lg:block">
            <img
              src="/images/LandingImg/news-arowleft.png"
              className="h-auto w-200"
              alt="shape"
            />
          </div>
          <div className="absolute right-20 top-20 hidden lg:block">
            <img
              src="/images/LandingImg/news-arrowright.png"
              alt="shape"
              className="h-auto w-280"
            />
          </div>
          <div className="absolute bottom-0 left-20 hidden lg:block">
            <img
              src="/images/LandingImg/ballglobal.png"
              alt="shape"
              className="h-auto w-80"
            />
          </div>
          <div className="absolute -right-40 bottom-50 hidden lg:block">
            <img
              src="/images/LandingImg/ballglobal.png"
              alt="shape"
              className="h-auto w-100"
            />
          </div>
        </div>
      </section>

      <section className="Blog-section relative overflow-hidden bg-white py-40 text-black lg:pb-0 lg:pt-100">
        <div className="landing-container mx-auto max-w-xl space-y-16 text-center">
          <div className="mx-auto max-w-xl space-y-16 text-center">
            <h2 className="animate-fade-down font-archivo text-3xl font-bold md:text-4xl lg:text-5xl">
              Get Updated{' '}
              <span className="color-primary-gradient">Articles</span>
            </h2>
            <p className="color-gray font-inter animate-fade-down">
              Stay informed with our latest insights and tips on cloud storage,
              data security, and best practices. Ensuring you stay ahead in the
              ever-evolving world of cloud technology.
            </p>
          </div>

          {/* <!-- Blog Posts Grid --> */}
          <div className="animate-fade-up grid grid-cols-1 gap-40 xl:grid-cols-2">
            {/* <!-- Blog Post 1 --> */}
            <div className="bg-light-primary2 overflow-hidden rounded-2xl border">
              <img
                src="/images/LandingImg/blog.png"
                alt="Blog Image"
                className="w-full object-cover"
              />
              <div className="font-inter space-y-16 p-24 text-start">
                <div className="flex items-center justify-between">
                  <div className="rounded-lg bg-primary p-8 font-normal">
                    01 January, 2025
                  </div>
                  <div className="color-gray font-semibold">
                    By <span className="color-primary">Admin</span>
                  </div>
                </div>
                <h3 className="font-archivo text-3xl font-bold">
                  <a href="https://manavtricks.in/pages/articles">
                    Our Free, Unlimited Space
                  </a>
                </h3>
                <p className="color-gray">
                  Are you buried with media? Manavtricks Cloud Storage is here
                  to save the day! With our massive free storage plan, you can
                  store all your valuable information securely in one place.
                  Click here for this{' '}
                  <a href="/" className="color-primary underline">
                    get more info.
                  </a>{' '}
                  No more fretting about running out of space on your phone.
                  Manavtricks Cloud Storage is the perfect solution for people
                  who need a reliable and easy way to manage their digital life.
                </p>

                {/* Checkpoints Section */}
                <div className="font-inter space-y-4">
                  <div className="flex items-center">
                    <span className="mr-4">
                      <CheckCircleOutlineIcon color="green" />
                    </span>
                    <span className="font-medium">
                      Get your free account today !!
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="mr-4">
                      <CheckCircleOutlineIcon color="green" />
                    </span>
                    <span className="font-medium">
                      Store all your important files securely.
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="mr-4">
                      <CheckCircleOutlineIcon color="green" />
                    </span>
                    <span className="font-medium">
                      Experience unlimited storage space with unlimited
                      bandwidth.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-40">
              {/* <!-- Blog Post 2 --> */}
              <div className="bg-light-primary2 overflow-hidden rounded-2xl border">
                <div className="font-inter space-y-16 p-24 text-start">
                  <div className="flex items-center justify-between">
                    <div className="rounded-lg bg-black p-8 font-normal text-white">
                      01 January, 2025
                    </div>
                    <div className="color-gray font-semibold">
                      By <span className="color-primary">Admin</span>
                    </div>
                  </div>
                  <h3 className="font-archivo text-3xl font-bold">
                    <a
                      href="https://manavtricks.in/pages/articles"
                      className="color-primary"
                    >
                      Optimize Your Digital Space with Manavtricks Cloud
                      Storage.
                    </a>
                  </h3>
                  <p className="color-gray">
                    Are you overwhelmed by the ever-growing amount of
                    information on your devices? Manavtricks Cloud Storage
                    offers a powerful solution to liberate valuable space. Our
                    robust cloud platform allows you to archive all your photos
                    securely and view them anytime, anywhere. With Manavtricks,
                    you can experience a clutter-free digital life and devote
                    your time to what truly matters.
                  </p>
                </div>
              </div>

              {/* <!-- Blog Post 3 --> */}
              <div className="bg-light-primary2 overflow-hidden rounded-2xl border">
                <div className="font-inter space-y-16 p-24 text-start">
                  <div className="flex items-center justify-between">
                    <div className="rounded-lg bg-black p-8 font-normal text-white">
                      01 January, 2025
                    </div>
                    <div className="color-gray font-semibold">
                      By <span className="color-primary">Admin</span>
                    </div>
                  </div>
                  <h3 className="font-archivo text-3xl font-bold">
                    <a
                      href="https://manavtricks.in/pages/articles"
                      className="color-primary"
                    >
                      Manavtricks Cloud Storage : The Leading Cloud Storage
                      Solution.
                    </a>
                  </h3>
                  <p className="color-gray">
                    Manavtricks provides a variety of storage capabilities
                    ranging from basic personal accounts to enterprise-grade
                    solutions designed for large organizations. Users can easily
                    upload files, collaborate with colleagues in real-time, and
                    access their data from any device with an internet
                    connection. Manavtricks is committed to providing a reliable
                    and budget-friendly cloud storage solution. The platform's
                    advanced security features...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Community-section relative overflow-hidden bg-white py-40 text-black lg:py-100">
        {/* Container */}
        <div
          className="landing-container relative z-10 space-y-8 bg-contain bg-center bg-no-repeat py-0 text-center lg:py-80"
          style={{ backgroundImage: "url('/images/LandingImg/map.png')" }}
        >
          <div className="mx-auto max-w-xl space-y-16 text-center">
            {/* Title */}
            <h2 className="animate-fade-down font-archivo text-3xl font-bold md:text-4xl lg:text-5xl">
              Join Our <span className="color-primary-gradient">Community</span>
            </h2>

            {/* Description */}
            <p className="color-gray font-inter animate-fade-down mx-auto">
              Join the{' '}
              <a href="#" className="color-primary font-semibold">
                Manavtricks Cloud Storage community
              </a>{' '}
              today and start Storing, Sharing, and Accessing your files from
              anytime, anywhere.
            </p>

            {/* Button */}
            <div className="flex justify-center">
              <a href="/register" className="btn-warning">
                <span>Get Started</span>
              </a>
            </div>
          </div>
        </div>

        {/* Icons (Decorative) */}
        <div className="absolute left-12 top-80 hidden md:left-40 md:block lg:left-160">
          <img
            src="/images/LandingImg/facebook-element.png"
            alt="Facebook"
            className="w-16 md:w-24 lg:w-auto"
          />
        </div>
        <div className="absolute bottom-80 left-12 hidden md:left-64 md:block lg:left-200">
          <img
            src="/images/LandingImg/chat-elements.png"
            alt="Chat"
            className="w-16 md:w-24 lg:w-auto"
          />
        </div>
        <div className="absolute right-12 top-60 hidden md:right-40 md:block lg:right-160">
          <img
            src="/images/LandingImg/bell-elements.png"
            alt="Notification Bell"
            className="w-16 md:w-32 lg:w-auto"
          />
        </div>
        <div className="absolute bottom-100 right-12 hidden md:right-64 md:block lg:right-200">
          <img
            src="/images/LandingImg/facebook-element.png"
            alt="Facebook"
            className="w-16 md:w-24 lg:w-auto"
          />
        </div>
      </section>

      <footer className="Footer-section relative overflow-hidden bg-primary-dark text-white">
        {/* Footer Mask */}
        <div>
          <img
            src="/images/LandingImg/footershape.png"
            alt="mask"
            className="w-full"
          />
        </div>

        <div className="landing-container z-10 mb-40">
          {/* Footer Top */}
          <div className="my-24 flex flex-col items-center justify-center space-y-16 sm:flex-row sm:justify-between lg:space-y-0">
            {/* Logo Section */}
            <a href="index.html" className="flex-shrink-0">
              <img
                src="/images/logo-light.png"
                alt="Logo"
                className="max-h-48"
              />
            </a>

            {/* Links */}
            <ul className="font-inter flex items-center space-x-16 text-center text-white sm:flex-row lg:text-start">
              <li>
                <a href="/register" className="hover:text-warning">
                  Register
                </a>
              </li>
              <li>
                <a href="/login" className="hover:text-warning">
                  Login
                </a>
              </li>
              <li>
                <a href="/pricing" className="hover:text-warning">
                  Pricing
                </a>
              </li>
              <li>
                <a href="/contact" className="hover:text-warning">
                  Contact
                </a>
              </li>
            </ul>

            {/* Social Icons */}
            <ul className="flex space-x-16">
              <li className="bg-light rounded-lg p-8">
                <a href="https://www.facebook.com/profile.php?id=61567307277313&mibextid=ZbWKwL">
                  <FacebookIcon className="text-white" />
                </a>
              </li>
              <li className="bg-light rounded-lg p-8">
                <a href="https://www.instagram.com/manavtricks.in/profilecard/?igsh=MWRqaG11bmI0NGJyMg%3D%3D">
                  <InstagramIcon className="text-white" />
                </a>
              </li>
              <li className="bg-light rounded-lg p-8">
                <a href="https://www.youtube.com/@manavtrickscloudstorage">
                  <YoutubeIcon className="text-white" />
                </a>
              </li>
            </ul>
          </div>

          {/* Footer Bottom */}
          <div className="font-inter border-t-1 border-t-primary/80 py-16 text-center text-white">
            <p className="text-sm">
              Copyright © 2025{' '}
              <a href="/login" className="color-primary2">
                Manavtricks Cloud Storage
              </a>
              , All Rights Reserved
            </p>
          </div>
        </div>
      </footer>
    </Fragment>
  );

  function handleResellerSubmit(value: any) {
    setResellerLoader(true);
    const data: any = {
      name: value.name,
      email: value.email,
      message: value.message,
      mobileNumber: value.mobileNumber,
    };

    submitForm.mutate(data, {
      onSuccess: () => {
        form.reset(); // Clears the form after successful submission
        setResellerLoader(false);
      },
      onError: () => {
        setResellerLoader(false);
      },
    });
  }
}

function CalculateDialogTrigger() {
  const form = useForm<ContactPagePayload>({
    defaultValues: {
      message: '', // Initialize message field
    },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);
  const submitForm = useSubmitContactForm(form);
  const { verify, isVerifying } = useRecaptcha('contact');

  return (
    <>
      <DialogTrigger type="modal" isOpen={isOpen} onOpenChange={setIsOpen}>
        <button className="btn-warning rounded-lg px-6 py-3 text-lg">
          <span>Get Quote</span>
        </button>
        <Dialog className="w-full max-w-lg rounded-xl p-6">
          <DialogHeader
            className="font-archivo text-2xl font-semibold"
            showDivider
          >
            <Trans message="Storage Cost Calculator" />
          </DialogHeader>
          <DialogBody>
            <div className="font-inter flex items-center justify-center">
              Enter the storage size (whole number greater than **5 TB**) to
              calculate your monthly cost.
            </div>
            <Form
              form={form}
              className="mt-4 flex flex-col items-center text-center"
              onSubmit={async value => {
                calculateCost(value);
              }}
            >
              <FormTextField
                label={<Trans message="Enter Storage in TB" />}
                name="tb"
                required
                type="number"
                className="mb-6 w-full rounded-lg p-2"
              />
              <div className="text-red-500 text-md" id="errorMsg"></div>
              <div className="text-blue-500 text-md" id="resultOutput"></div>
              <div className="mt-6 flex w-full justify-between gap-4">
                <button className="btn-warning mt-16" type="submit">
                  Calculate
                </button>
                <Button
                  color="positive"
                  variant="outline"
                  className="mt-16 rounded-lg"
                  type="button"
                  onClick={() => {
                    const resultOutput =
                      document.getElementById('resultOutput')?.textContent;
                    form.setValue('message', resultOutput || ''); // Set the value of message field
                    setIsOpen(false); // Close current dialog
                    setIsContactDialogOpen(true); // Open Contact Us dialog
                  }}
                >
                  Contact Us
                </Button>
              </div>
            </Form>
          </DialogBody>
        </Dialog>
      </DialogTrigger>

      {isContactDialogOpen && (
        <DialogTrigger
          type="modal"
          isOpen={isContactDialogOpen}
          onOpenChange={setIsContactDialogOpen}
        >
          <Dialog className="w-full max-w-lg rounded-xl p-6">
            <DialogHeader
              className="font-archivo text-2xl font-semibold"
              showDivider
            >
              <Trans message="Contact Us" />
            </DialogHeader>
            <DialogBody>
              {submitForm.isPending ? (
                <FullPageLoader />
              ) : (
                <Form
                  form={form}
                  className="flex flex-col gap-4"
                  onSubmit={async value => {
                    const isValid = await verify();
                    if (isValid) {
                      submitForm.mutate(value, {
                        onSuccess: () => {
                          setIsContactDialogOpen(false); // Close the dialog on success
                        },
                      });
                    }
                  }}
                >
                  <FormTextField
                    label={<Trans message="Name" />}
                    name="name"
                    required
                    className="rounded-lg p-2"
                  />
                  <FormTextField
                    label={<Trans message="Email" />}
                    name="email"
                    required
                    type="email"
                    className="rounded-lg p-2"
                  />
                  <FormTextField
                    label={<Trans message="Mobile Number" />}
                    name="mobileNumber"
                    required
                    type="number"
                    className="rounded-lg p-2"
                  />
                  <FormTextField
                    label={<Trans message="Message" />}
                    name="message"
                    required
                    inputElementType="textarea"
                    className="rounded-lg p-2"
                    rows={5}
                  />

                  <div className="mt-4 flex w-full justify-between gap-4">
                    <Button
                      variant="flat"
                      onClick={() => setIsContactDialogOpen(false)}
                      className="rounded-lg px-4 py-2 text-lg"
                    >
                      <Trans message="Back" />
                    </Button>
                    <Button
                      type="submit"
                      variant="flat"
                      color="primary"
                      className="rounded-lg px-4 py-2 text-lg"
                    >
                      <Trans message="Send Message" />
                    </Button>
                  </div>
                </Form>
              )}
            </DialogBody>
          </Dialog>
        </DialogTrigger>
      )}
    </>
  );
}

function calculateCost({ tb }: any) {
  const tbInput: any = tb;
  const errorMsg: any = document.getElementById('errorMsg');
  const resultOutput: any = document.getElementById('resultOutput');

  // Reset messages
  errorMsg.textContent = '';
  resultOutput.textContent = '';

  // Validation: Ensure input is a positive integer and greater than 5
  if (!/^\d+$/.test(tbInput)) {
    errorMsg.textContent = 'Error: Storage must be a whole number!';
    return;
  }

  const tbValue = parseInt(tbInput, 10);

  if (tbValue <= 5) {
    errorMsg.textContent = 'Error: Storage must be greater than 5 TB!';
    return;
  }

  // Conversion and cost calculation
  const gbStorage = tbValue * 1024; // Convert TB to GB
  const cost = gbStorage * 0.15; // Calculate cost

  // Display result
  resultOutput.innerHTML = `
      Storage: <strong>${tbValue.toLocaleString()} TB</strong><br>
      Total Cost: <strong>₹${cost.toFixed(2)}/month</strong><br>
      GST and Convenience Fee Extra**
  `;
}
