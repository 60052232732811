import {Radio} from '@ui/forms/radio-group/radio';
import {UpsellBillingCycle} from './find-best-price';
import {Trans} from '@ui/i18n/trans';
import {RadioGroup, RadioGroupProps} from '@ui/forms/radio-group/radio-group';
import {UpsellLabel} from './upsell-label';
import {Product} from '../product';

interface BillingCycleRadioProps extends Omit<RadioGroupProps, 'children'> {
  selectedCycle: UpsellBillingCycle;
  onChange: (value: UpsellBillingCycle) => void;
  products?: Product[];
}
export function BillingCycleRadio({
  selectedCycle,
  onChange,
  products,
  ...radioGroupProps
}: BillingCycleRadioProps) {
  return (
    <RadioGroup {...radioGroupProps}>
      <Radio
        value="month"
        checked={selectedCycle === 'month'}
        onChange={e => {
          if (e.target.checked) {
            onChange('month');
          }
        }}
      >
        <Trans message="Monthly" />
      </Radio>

      <Radio
        value="year"
        checked={selectedCycle === 'year'}
        onChange={e => {
          if (e.target.checked) {
            onChange('year');
          }
        }}
      >
        <Trans message="Annual" />
        <UpsellLabel products={products} selectedCycle={selectedCycle} />
      </Radio>
    </RadioGroup>
  );
}
