// find the highest percentage decrease between monthly and yearly prices of specified products
import { Product } from '../product';
import { findBestPrice } from './find-best-price';
import { Fragment, memo } from 'react';
import { Trans } from '@ui/i18n/trans';

interface UpsellLabelProps {
  products?: Product[];
  selectedCycle?: string;
}
export const UpsellLabel = memo(
  ({ products, selectedCycle }: UpsellLabelProps) => {
    const upsellPercentage = calcHighestUpsellPercentage(products);

    if (upsellPercentage <= 0) {
      return null;
    }

    return (
      <Fragment>
        <span
          className={`${selectedCycle === 'yearly' && 'color-green'}`}
        >
          {' '}
          (
          <Trans
            message="up to :percentage% OFF"
            values={{ percentage: upsellPercentage }}
          />
          )
        </span>
      </Fragment>
    );
  },
);

function calcHighestUpsellPercentage(products?: Product[]) {
  if (!products?.length) return 0;

  const decreases = products.map(product => {
    if (product.hidden) return 0;

    const monthly = findBestPrice('month', product.prices);
    const yearly = findBestPrice('year', product.prices);

    if (!monthly || !yearly) return 0;

    // monthly plan per year amount
    const monthlyAmount = monthly.amount * 12;
    const yearlyAmount = yearly.amount;

    const savingsPercentage = Math.round(
      ((monthlyAmount - yearlyAmount) / monthlyAmount) * 100,
    );

    if (savingsPercentage > 0 && savingsPercentage <= 200) {
      return savingsPercentage;
    }

    return 0;
  });

  return Math.max(Math.max(...decreases), 0);
}
