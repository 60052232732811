import { IllustratedMessage } from '@ui/images/illustrated-message';
import { ErrorIcon } from '@ui/icons/material/Error';
import { Trans } from '@ui/i18n/trans';
import { Button } from '@ui/buttons/button';
import { Link } from 'react-router-dom';

export function PageErrorMessage() {
  return (<>
    <IllustratedMessage
      className="mt-40"
      image={
        <div>
          <ErrorIcon size="xl" />
        </div>
      }
      imageHeight="h-auto"
      title={<Trans message="There was an issue loading this page" />}
      description={<Trans message="Please try again later" />}
    />
    <Button
      className="my-8"
      elementType={Link}
      size="lg"
      to="/login"
      variant="flat"
      color="primary"
    >
      <Trans message="Take me there!" />
    </Button>
  </>);
}
