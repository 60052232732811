import { AnimatePresence, m } from 'framer-motion';
import { Fragment } from 'react';
import { opacityAnimation } from '@ui/animation/opacity-animation';
import { Skeleton } from '@ui/skeleton/skeleton';
import { useProducts } from '@common/billing/pricing-table/use-products';
import { Product } from '@common/billing/product';
import {
  findBestPrice,
  UpsellBillingCycle,
} from '@common/billing/pricing-table/find-best-price';
import { useAuth } from '@common/auth/use-auth';
import clsx from 'clsx';
import { Chip } from '@ui/forms/input-field/chip-field/chip';
import { Trans } from '@ui/i18n/trans';
import { FormattedPrice } from '@common/billing/formatted-price';
import { Button } from '@ui/buttons/button';
import { Link } from 'react-router-dom';
import { setInLocalStorage } from '@ui/utils/hooks/local-storage';
import { ProductFeatureList } from '@common/billing/pricing-table/product-feature-list';
import { useBillingUser } from '../billing-page/use-billing-user';
import { Subscription } from '../subscription';

interface PricingTableProps {
  selectedCycle: UpsellBillingCycle;
  className?: string;
  productLoader?: string;
}
export function PricingTable({
  selectedCycle,
  className,
  productLoader,
}: PricingTableProps) {
  const query = useProducts(productLoader);
  const { subscription }: any = useBillingUser();
  return (
    <div
      className={clsx(
        'flex flex-col items-stretch gap-24 overflow-x-auto overflow-y-visible pb-16 md:flex-row md:justify-start',
        className,
      )}
      style={{ scrollbarColor: 'auto' }}
    >
      <AnimatePresence initial={false} mode="wait">
        {query.data ? (
          <PlanList
            key="plan-list"
            plans={query.data.products}
            selectedPeriod={selectedCycle}
            subscription={subscription}
          />
        ) : (
          <SkeletonLoader key="skeleton-loader" />
        )}
      </AnimatePresence>
    </div>
  );
}

interface PlanListProps {
  plans: Product[];
  selectedPeriod: UpsellBillingCycle;
  subscription: any;
}
function PlanList({ plans, selectedPeriod, subscription }: PlanListProps) {
  const { isLoggedIn, isSubscribed } = useAuth();

  const filteredPlans = plans.filter(
    plan =>
      !plan.hidden &&
      plan.prices[0].interval ===
      (selectedPeriod === 'month' ? 'month' : 'year'),
  );

  return (
    <Fragment>
      {filteredPlans
        .filter((plan: any) => {
          if (
            subscription &&
            subscription.product &&
            new Date(subscription.ends_at) >= new Date()
          ) {
            return plan.available_space > subscription.product.available_space &&
              plan.prices[0].interval === subscription.price.interval;
          }
          return true;
        })
        .map((plan: any, index) => {
          const isFirst = index === 0;
          const isLast = index === filteredPlans.length - 1;
          const price = findBestPrice(selectedPeriod, plan.prices);

          let upgradeRoute: any;
          if (!isLoggedIn) {
            upgradeRoute = `/register?redirectFrom=pricing`;
          }
          if (isSubscribed) {
            upgradeRoute = `/change-plan/${plan.id}/${price?.id}/confirm`;
          }
          if (isLoggedIn && !plan.free) {
            upgradeRoute = `/checkout/${plan.id}/${price?.id}`;
          }

          return (
            <m.div
              key={plan.id}
              {...opacityAnimation}
              className={clsx(
                'border-primary-input w-full rounded-lg border bg-white px-28 py-28 text-black md:min-w-264 md:max-w-400',
                isFirst && 'ml-auto',
                isLast && 'mr-auto',
              )}
              style={{ fontFamily: 'Inter ,serif' }}
            >
              <div className="mb-10">
                <Chip
                  radius="rounded"
                  size="sm"
                  className={clsx(
                    'mb-20 w-min',
                    !plan.recommended && 'invisible',
                  )}
                >
                  <Trans message="Most popular" />
                </Chip>
                <div
                  className="mb-4 text-2xl font-bold"
                  style={{ fontFamily: 'Archivo ,serif' }}
                >
                  <Trans message={plan.name} />
                </div>
                <div className="text-sm text-black">
                  <Trans message={plan.description} />
                </div>
              </div>
              <div>
                {price ? (
                  <FormattedPrice
                    priceClassName="font-bold text-xl color-primary"
                    periodClassName="text-sm font-semibold"
                    variant="separateLine"
                    price={price}
                  />
                ) : (
                  <div className="text-4xl font-bold">
                    <Trans message="Free" />
                  </div>
                )}
                <div className="mt-50">
                  <Button
                    variant={plan.recommended ? 'flat' : 'outline'}
                    color="primary"
                    className="w-full rounded-lg text-white hover:text-black"
                    size="md"
                    elementType={upgradeRoute ? Link : undefined}
                    disabled={!upgradeRoute}
                    onClick={() => {
                      if (isLoggedIn || !price || !plan) return;
                      setInLocalStorage('be.onboarding.selected', {
                        productId: plan.id,
                        priceId: price.id,
                      });
                    }}
                    to={upgradeRoute}
                  >
                    {plan.free ? (
                      <Trans message="Get started" />
                    ) : (
                      <Trans message="Upgrade" />
                    )}
                  </Button>
                </div>
                <ProductFeatureList product={plan} />
              </div>
            </m.div>
          );
        })}
    </Fragment>
  );
}

function SkeletonLoader() {
  return (
    <Fragment>
      <PlanSkeleton key="skeleton-1" />
      <PlanSkeleton key="skeleton-2" />
      <PlanSkeleton key="skeleton-3" />
    </Fragment>
  );
}

function PlanSkeleton() {
  return (
    <m.div
      {...opacityAnimation}
      className="w-full rounded-lg border px-28 py-90 shadow-lg md:max-w-350"
    >
      <Skeleton className="my-10" />
      <Skeleton className="mb-40" />
      <Skeleton className="mb-40 h-30" />
      <Skeleton className="mb-40 h-40" />
      <Skeleton className="mb-20" />
      <Skeleton />
      <Skeleton />
    </m.div>
  );
}
